.ai-project {
    .pricingPlan {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        padding: 20px;

        .pricing-card {
            border-radius: 20px;
            padding: 20px;
            background: #f9f9ff;

            .top {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 20px;

                .icon {
                    background: #ecebff;
                    border-radius: 15px;
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                .category {
                    font-size: 18px;
                    color: #6f6c90;
                }

                .title {
                    font-size: 22px;
                    font-weight: 700;
                    color: #170f49;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-bottom: 40px;

                .items {
                    display: flex;
                    align-items: center;

                    .left-item {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        width: 65%;
                    }

                    .price {
                        font-weight: 600;
                        color: #000;
                        border-left: 2px solid #c3c3c3;
                        padding-left: 10px;
                    }
                }
            }
        }

        .book-appointment {
            border-radius: 20px;
            position: relative;
            text-align: center;
            min-height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center; // Center align content horizontally

            .bg-img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
                z-index: -1;
            }

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                button {
                    padding: 26px 40px;
                    border-radius: 80px;
                    background: white;
                    color: #6F00FF;
                    font-weight: 700;
                    font-size: 18px;
                }

                .bot {
                    width: 100%;
                    height: auto;
                    margin-left: -10%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .ai-project {
        .pricingPlan {
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
            padding: 0px;
            padding-top: 40px;

            .pricing-card {
                padding: 0px;


                .content {
                    .items {
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;


                        .left-item {
                            display: flex;
                            column-gap: 10px;
                            width: 100%;

                            img {
                                width: 20px;
                            }

                            p {
                                font-size: 16px;
                            }
                        }

                        .price {
                            border: none;
                            padding-left: 35px;

                        }
                    }
                }
            }

            .book-appointment {
                min-height: 400px;

                .content {
                    padding-top: 20px;
                    gap: 20px;

                    button {
                        padding: 14px 20px;
                        color: #6F00FF !important;
                        font-size: 18px;
                        font-weight: 600;
                    }

                }
            }
        }

    }
}