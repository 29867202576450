.ar-vr-sec {
    .heading {
        padding-bottom: 60px;
    }

    .content {
        padding: 60px 60px 60px 40px;
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        gap: 20px;

        .bg-img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 20px;
        }

        .left {
            width: 55%;

            .top {
                display: flex;
                gap: 20px;

                .head {
                    font-size: 25px;
                    font-weight: 500;
                    color: white;
                }

                h3 {
                    font-size: 34px;
                    font-weight: 700;
                    color: white;
                }

                .dsc {
                    font-size: 20px;
                    font-weight: 400;
                    color: white;
                }

                .img {
                    background: white;
                    border-radius: 16px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .brows-img {
                        width: 83px;
                        height: 67px;
                    }
                }
            }

            .bottom {
                padding-top: 80px;

                .items {
                    display: flex;
                    gap: 32px;

                    .item {

                        background-color: white;
                        border-radius: 23px;
                        padding: 40px 60px;
                        gap: 20px !important;
                        flex: 1;

                        .project-types {
                            display: flex !important;
                            align-items: center;
                            justify-content: space-between;

                        }

                        .left-item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            width: 65%;
                        }


                        .price {
                            font-weight: 600;
                            color: #000;
                            border-left: 2px solid #c3c3c3;
                            padding-left: 10px;
                        }



                    }


                }

            }
        }

        .right {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .btn {
                padding-top: 18px;
                display: flex;
                justify-content: center;

                button {
                    padding: 26px 40px;
                    border-radius: 80px;
                    background: white;
                    color: #6F00FF;
                    font-weight: 700;
                    font-size: 18px;
                    width: fit-content;
                }

            }

            .diamond1 {
                position: absolute;
                top: 20%;
                left: 0;
            }

            .diamond2 {
                position: absolute;
                top: 10%;
                right: 0;

            }

            .diamond3 {
                position: absolute;
                top: 50%;
                right: 0;
            }

        }

    }

}

@media screen and (max-width: 600px) {
    .ar-vr-sec {
        .heading {
            padding-bottom: 40px;
        }

        .content {
            padding: 30px 16px;
            flex-direction: column !important;

            .left {
                width: 100%;

                .top {
                    flex-direction: column;
                    text-align: center;

                    .img {
                        width: fit-content;
                        margin: 0 auto;
                    }

                    .head {
                        font-size: 20px;
                    }

                    h3 {
                        font-size: 24px;
                    }

                    .dsc {
                        font-size: 16px;
                    }


                }
            }

            .diamond1,
            .diamond2,
            .diamond3 {
                width: 30px;
            }

            .bottom {
                padding-top: 20px !important;

                .item {
                    padding: 20px 16px !important;


                    .project-types {
                        flex-direction: column;
                        align-items: start !important;
                        padding: 5px 0;
                        width: 100%;

                        .left-item {
                            display: flex;
                            gap: 10px;
                            width: 100% !important;

                            img {
                                width: 20px;
                            }

                            p {
                                font-size: 16px;
                            }
                        }

                        .pr {
                            border: none !important;
                            padding-left: 35px;
                            padding-top: 4px;


                            .price {
                                border: none;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .right {
                display: flex;

                .img {
                    display: flex;
                    justify-content: center;

                    .arBot {
                        width: 50% !important;
                        margin: 0 auto;

                    }

                }

                .btn {
                    button {
                        padding: 12px 20px;
                        color: #6F00FF !important;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }

    }
}