.get-to-know {
  padding: 100px 50px 146px 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  .parent {
    gap: 118px;
    max-width: 1700px;
    display: flex;
    align-items: center;
    justify-content: center;

    .left-content {
      width: 50%;

      .heading-1 {
        position: relative;

        .top {
          position: absolute;
          top: -11px;
          left: 17%;
        }

        .bottom {
          position: absolute;
          bottom: -30%;
          left: 36%;
        }
      }

      h6 {
        color: #000;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        /* 175% */
      }

      .about-btn {
        padding: 5px 6px;
        margin-top: 20px;
        border-radius: 25px;
        border: 2px solid #9498ff;
        background: #6b00fa;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        width: fit-content;

        button {
          padding: 9px 18px;
          border-radius: 23.621px;
          background: rgba(145, 148, 252, 0.6);
          box-shadow: 0px 0px 3.779px 0px rgba(0, 0, 0, 0.25);
          color: var(--white);
          font-family: "Montserrat";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .right-content {
    width: 50%;
    position: relative;

    .layers {
      .mainimg-div {
        width: 100%;
        position: relative;
        z-index: 2;

        .main-img {
          width: 100%;
          height: 100%;
        }

        .btn-wrapper {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          right: 50%;
          bottom: 50%;
          transform: translate(50%, 50%);

          .videomain-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
          }

          .animate-btn {
            position: unset;
          }
        }

        .animate-btn {
          animation: animatedbtn 2s linear infinite;
        }

        @keyframes animatedbtn {
          0% {
            transform: rotate(0);
          }

          50% {
            transform: rotate(180deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      .inner {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        background: rgba(148, 152, 255, 0.2);
        z-index: 1;
      }

      .outer {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        background: rgba(111, 0, 255, 0.1);
      }
    }
  }
}

.video-modal {
  .ant-modal-content {
    background: rgb(111, 0, 255, 0.9);
    box-shadow: none;
    padding: 14px;
  }

  .ant-modal-close {
    // background-color: red;
    z-index: 3;
    width: 30px;
    height: 30px;

    .ant-modal-close-x {
      font-size: 24px;
      font-weight: 900;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1300px) {
  .get-to-know {
    .parent {
      gap: 80px;

      .left-content {
        .heading-1 span::after {
          width: 90px;
          height: 90px;
        }

        h5 {
          font-size: 35px;
        }

        h6 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .get-to-know {
    padding: 50px 100px;

    .parent {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      gap: 50px;

      .left-content {
        width: 100%;
      }

      .right-content {
        width: 100%;

        .layers {
          .inner {
            display: none;
          }

          .outer {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .get-to-know {
    padding: 25px 20px;

    .parent {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      gap: 20px;

      .left-content {
        width: 100%;

        .heading-1 {
          span::after {
            width: 80px;
            height: 80px;
          }

          .top {
            left: 24%;
          }

          .bottom {
            left: 40%;
            width: 100px;
          }
        }

        h5 {
          font-size: 25px;
        }

        h6 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
          line-height: 25px;
        }
      }

      .right-content {
        width: 100%;

        .layers {
          .inner {
            display: none;
          }

          .outer {
            display: none;
          }
        }
      }
    }
  }

  :where(.css-dev-only-do-not-override-1k979oh).ant-modal-root .ant-modal-wrap {
    display: flex;
    align-items: center;
  }

  :where(.css-dev-only-do-not-override-1k979oh).ant-modal-root .ant-modal {
    top: 0px;
  }

  .video-modal .ant-modal-content {
    padding: 10px;
  }

  :where(.css-dev-only-do-not-override-1k979oh).ant-modal .ant-modal-body {
    line-height: unset;
  }
}