@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

h1 {
  font-size: 9.4vw;
  // font-size: 180px;
  font-weight: 800;
}

h2 {
  font-size: 3.13vw;
  font-weight: 700;
}

h3 {
  font-size: 50px;
}

h5 {
  font-size: 40px;
}

h6 {
  font-size: 35px;
  letter-spacing: 8.05px;
}

label {
  font-size: 22px;
  font-weight: 700;
}

.large-para {
  font-size: 20px;
  font-weight: 400;
}

p {
  font-size: 20px;
}

.large {
  font-size: 25px;
}

p.medium {
  font-size: 18px;
  font-weight: 500;
}

.small-para {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.large-paraDeci {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #717171;
  text-align: center;
}

.text-purple {
  color: var(--primary);
}

.text-gray {
  color: var(--gray);
}

.no-letter-spacing {
  letter-spacing: normal;
}

.text-roboto {
  font-family: "Roboto" !important;
}

@media screen and (max-width: 700px) {
  p {
    font-size: 18px;
  }
}

.text-light-purple {
  color: #656ae3;
}