.blockchain-sec {
    .heading {
        padding-bottom: 60px;
    }

    .blockchain {
        padding: 60px 45px;
        background: black;
        border-radius: 20px;
        position: relative;


        .top {
            display: flex;
            gap: 20px;
            width: 70%;

            .category {
                font-size: 25px;
                font-weight: 500;
                color: white;

            }

            .title {
                font-size: 34px;
                font-weight: 700;
                color: white;
            }

            .dsc {
                font-size: 20px;
                font-weight: 400;
                color: white;
            }

            .logo {
                background: white;
                border-radius: 16px;
                padding: 0 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 68px;
                    height: 67px;
                }
            }
        }

        .items {
            padding-top: 60px;
            display: flex;
            gap: 32px;

            .item {

                background-color: white;
                border-radius: 23px;
                padding: 40px 60px;
                gap: 20px !important;
                flex: 1;

                .project-types {
                    display: flex !important;
                    align-items: center;
                    padding: 5px 0;
                    justify-content: space-between;

                }

                .left {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 65%;
                }

                .price {
                    font-weight: 600;
                    color: #000;
                    border-left: 2px solid #c3c3c3;
                    padding-left: 10px;
                }

            }


        }

        .btn {
            padding-top: 40px;
            display: flex;
            justify-content: end;

            button {
                padding: 26px 40px;
                border-radius: 80px;
                background: white;
                color: #6F00FF;
                font-weight: 700;
                font-size: 18px;
                width: fit-content;
            }

        }

        .coin {
            position: absolute;
            top: 0;
            left: 60%;
            width: 100px;
        }

        .athcoin {
            position: absolute;
            top: 5%;
            right: 15%;
            width: 180px;
        }

        .bitcoin {
            position: absolute;
            bottom: 12%;
            left: 4%;
            width: 160px;
        }
    }

}

@media screen and (max-width: 600px) {
    .blockchain-sec {
        .heading {
            padding-bottom: 40px;
        }

        .blockchain {
            padding: 30px 16px;

            .top {
                flex-direction: column;
                width: 100%;
                text-align: center;

                .logo {
                    padding: 6px;
                    width: fit-content;
                    margin: 0 auto;
                }

                .category {
                    font-size: 20px;
                }

                .title {
                    font-size: 24px;

                }

                .dsc {
                    font-size: 16px;
                }

            }

            .items {
                flex-direction: column !important;
                padding-top: 40px;

                .item {
                    padding: 20px 10px !important;
                    padding-left: 20px !important;


                    .project-types {
                        padding: 5px 0;
                        flex-direction: column;
                        align-items: start;

                        .left {
                            width: 100%;



                            img {
                                width: 20px;
                            }

                            p {
                                font-size: 16px !important;
                            }
                        }

                        .price {
                            width: fit-content;
                            border: none;
                            padding-left: 35px;
                            padding-top: 5px;
                            font-size: 16px;


                        }
                    }
                }



            }

            div.btn {
                justify-content: center !important;
                padding-top: 25px;

                button {
                    padding: 12px 20px;
                    color: #6F00FF !important;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

        }

        .coin,
        .athcoin,
        .bitcoin {
            display: none;
        }
    }



}