.projects-price {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;

    .webApp {
        .content {
            margin-top: 40px;
            padding: 20px 60px 0px 0px;
            width: 100%;
            position: relative;
            border-radius: 20px;
            overflow: hidden;

            .bg-img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
                border-radius: 20px;
            }

            .card {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                background-color: transparent;
                border: none;
                padding: 40px;
                border-radius: 16px;

                .left {
                    grid-column: span 2;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .top {
                        display: flex;
                        gap: 20px;

                        .head {
                            font-size: 25px;
                            font-weight: 500;
                            color: white;
                        }

                        h3 {
                            font-size: 34px;
                            font-weight: 700;
                            color: white;
                        }

                        .dsc {
                            font-size: 20px;
                            font-weight: 400;
                            color: white;
                        }

                        .browsImg {
                            background: white;
                            border-radius: 16px;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .brows-img {
                                width: 83px;
                                height: 67px;
                            }
                        }
                    }

                    .bottom {
                        padding: 20px 0;

                        .items {
                            display: flex;
                            flex-direction: column;
                            gap: 40px;
                            padding: 40px 0;

                            .item {
                                display: flex;
                                gap: 24px;
                                align-items: center;

                                .item-left {
                                    display: flex;
                                    column-gap: 14px;

                                    p {
                                        color: white;
                                        width: 60%;
                                    }
                                }



                                .price {
                                    padding-left: 24px;
                                    border-left: 2px solid white;
                                    font-weight: 700;
                                    color: white;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }

                .right {
                    grid-column: span 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .appointment {
                display: flex;
                justify-content: space-between;
                padding: 20px 60px;
                padding-top: 0px;
                align-items: center;

                .typo {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    p {
                        color: white;
                        font-size: 18px;
                    }

                    img {
                        width: 36px;
                    }
                }

                .btn {
                    display: flex;
                    justify-content: end;

                    button {
                        padding: 26px 40px;
                        border-radius: 80px;
                        background: white;
                        color: #6F00FF;
                        font-weight: 700;
                        font-size: 18px;
                        width: fit-content;
                    }

                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .projects-price {
        gap: 40px;

        .webApp {
            .content {
                margin-top: 40px;
                padding: 10px;

                .card {
                    grid-template-columns: 1fr;
                    gap: 16px;
                    padding: 0px;

                    .left {
                        grid-column: span 1;
                        gap: 16px;

                        .top {
                            flex-direction: column;
                            align-items: center;
                            gap: 12px;
                            text-align: center;

                            .head {
                                font-size: 20px;
                            }

                            h3 {
                                font-size: 22px;
                            }

                            .dsc {
                                font-size: 16px;
                            }



                            .browsImg {
                                padding: 8px;

                                .brows-img {
                                    width: 70px;
                                    height: 56px;
                                }
                            }
                        }

                        .bottom {
                            padding: 0;

                            .items {
                                gap: 24px;
                                padding: 20px 0;

                                .item {
                                    flex-direction: column;
                                    gap: 12px;
                                    align-items: flex-start;

                                    .item-left {
                                        display: flex;
                                        gap: 10px;

                                        .check {
                                            width: 20px;
                                            height: 20px;
                                        }

                                        p {
                                            width: 100%;
                                            font-size: 16px;
                                        }
                                    }

                                    .price {
                                        padding-left: 35px;
                                        border-left: none;
                                        font-size: 16px;

                                    }
                                }
                            }
                        }
                    }

                    .right {
                        grid-column: span 1;
                        justify-content: center;

                        img {
                            width: 50%;
                        }
                    }
                }

                .appointment {
                    flex-direction: column;
                    gap: 16px;
                    padding: 20px;
                    align-items: center;

                    .typo {
                        gap: 4px;
                        text-align: center;

                        p {
                            font-size: 16px;
                        }

                        img {
                            width: 28px;
                            display: none;
                        }
                    }

                    .btn {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        button {
                            padding: 12px 20px;
                            color: #6F00FF !important;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}