.pricing {


    .projects {
        padding: 100px 50px;
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;

    }
}


@media screen and (max-width: 600px) {
    .pricing {

        .projects {
            padding: 50px 16px;
        }

    }
}